@use 'colors.scss';

html {
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

body {
  line-height: 1.7;
  letter-spacing: -0.1px;
  word-spacing: -0.8px;
}

h1 {
  font-size: 2.25rem;
  letter-spacing: 0.013rem;
  line-height: 2.5rem;

  @media screen and (min-width: 768px) {
    font-size: 2.625rem;
    line-height: 3.375rem;
    letter-spacing: 0.025rem;
  }

  @media screen and (min-width: 1280px) {
    font-size: 3rem;
    line-height: 3.75rem;
  }
}

h2, .h2 {
  font-size: 2rem;
  letter-spacing: 0.013rem;
  line-height: 2.375rem;

  @media screen and (min-width: 768px) {
    font-size: 2.25rem;
    line-height: 3rem;
    letter-spacing: 0.025rem;
  }
}

h3, .h3 {
  font-size: 1.75rem;
  letter-spacing: 0.006rem;
  line-height: 2rem;

  @media screen and (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.375rem;
    letter-spacing: 0.013rem;
  }
}

h4, .h4 {
  font-size: 1.5rem;
  letter-spacing: 0.006rem;
  line-height: 1.75rem;

  @media screen and (min-width: 768px) {
    font-size: 1.75rem;
    line-height: 2rem;
    letter-spacing: 0.013rem;
  }
}

h5, .h5 {
  font-size: 1.313rem;
  letter-spacing: 0.006rem;
  line-height: 1.5rem;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
}

h6, .h6 {
  font-size: 1.125rem;
  letter-spacing: 0.006rem;
  line-height: 1.313rem;

  @media screen and (min-width: 768px) {
    font-size: 1.313rem;
    line-height: 1.5rem;
  }
}

p,
input,
input[type='password'] {
  font-size: 1rem;
  letter-spacing: 0.013rem;
  line-height: 1.375rem;
}

p.paragraphLarge {
  font-size: 1.125rem;
  line-height: 2rem;
}

button {
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  font-size: 1rem;
  letter-spacing: 0.013rem;
  line-height: 1.125rem;
}

button.buttonLarge {
  font-size: 1.125rem;
  line-height: 2rem;
}

a {
  color: colors.$primary-color;
  font-size: 1rem;
  letter-spacing: 0.013rem;
  line-height: 1.375rem;
  text-decoration: underline;
}

a.linkLarge {
  font-size: 1.125rem;
  line-height: 2rem;
}

.caption {
  font-size: 0.75rem;
  letter-spacing: 0.013rem;
  line-height: 1.125rem;
}

.captionLarge {
  font-size: 0.875rem;
  letter-spacing: 0.013rem;
}

input,
textarea {
  font-family: 'TT Prosto Sans Condensed', sans-serif;
  font-size: 1rem;
}

input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-text-fill-color: colors.$default-text;
  background-color: transparent !important;
  background-image: none !important;
  box-shadow: 0 0 0px 1000px colors.$white inset;
}
