@use '../styleguide/breakpoints.scss';
@use '../styleguide/vars.scss';

@media screen and (min-width: vars.$extra-extra-large) {
  .container {
    max-width: vars.$extra-extra-large;
    margin: 0 auto;
  }
}
@include breakpoints.col-x(xxl, vars.$extra-extra-large);
