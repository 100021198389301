@use '../styleguide/colors.scss';
@use '../styleguide/vars.scss';

.floatingButton {
  fill: colors.$primary-color;
}

.float {
  align-items: flex-end;
}

.dailiesOpener {
  display: none;

  @media screen and (min-width: vars.$extra-large) {
    display: block;
    left: -60px;
    position: fixed;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);

    &:hover {
      left: -40px;
    }
  }
}

.appRoot {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  max-width: 100vw;
}