@use '../styleguide/layout-xs.scss';
@use '../styleguide/typography.scss';
@use '../styleguide/colors.scss';

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: normal;
  outline-color: colors.$primary-color;
}

:root {
  --secondary-color-light: #fb915b;
  --secondary-color: #f36a25;
  --secondary-color-dark: #df5713;

  --background: #f7f7f7;

  --white: #ffffff;
  --grey: #e2e2e2;
  --dark-grey: #555555;
  --light-grey: #E2E2E2;
  --red: #d0021b;
  --light-blue: #3293E6;

  --default-text: #2a2a2a;
  --secondary-text: #9b9b9b;
  --dark-text: #4a4a4a;
  --succes: #00c48c;
  --error: #ff4b50;
  --rating: #ffcf5c;
}

html,
#root {
  max-width: 100vw;
}

body {
  margin: 0;
  background-color: colors.$background;
  max-width: 100%;
  font-size: 1rem;
}

body > img {
  /* Hide TT pageview img to get rid of white line under the footer */
  /* Request will still be triggered */
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

svg {
  fill: currentColor;
}

strong,
b {
  font-stretch: condensed;
  font-weight: bold;
}

.GTMNoScriptIframe {
  display:none;
  visibility:hidden;
}

.hidden {
  display: none !important;
}

::-webkit-scrollbar {
  width: 10px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: none;
}

::-webkit-scrollbar-thumb {
  background: colors.$grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: colors.$secondary-text;
}

html {
  color: colors.$default-text;
}

.no-scroll {
  overflow: hidden !important;
}

.pac-container {
  z-index: 1050 !important;
}
