@use '../styleguide/breakpoints.scss';
@use '../styleguide/vars.scss';

:root {
  --gutter: #{vars.$gutterXS};
  --node-spacing: calc(#{vars.$gutterXS} / 2);
}

.container {
  display: flex;
  flex-flow: column;
  margin: 0 auto;
  padding-left: vars.$nodeSpacing;
  padding-right: vars.$nodeSpacing;
  width: 100%;
}

.row {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  column-gap: vars.$gutter;
}

@include breakpoints.col-x(xs, vars.$extra-small);