@use '../styleguide/breakpoints.scss';
@use '../styleguide/vars.scss';

@media screen and (min-width: vars.$small) {
  :root {
    --gutter: #{vars.$gutterGeneric};
    --node-spacing: calc(#{vars.$gutterGeneric} / 2);
  }
}
@include breakpoints.col-x(s, vars.$small);
