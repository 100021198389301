@mixin media($breakpoint, $maxWidth) {
  @if $breakpoint == xs {
    @content;
  } @else {
    @media screen and (min-width: #{$maxWidth}) {
      @content;
    }
  }
}

@mixin col-x($breakpoint, $maxWidth) {
  @include media($breakpoint, $maxWidth) {
    @for $i from 1 through 12 {
      @for $y from 1 through 12 {
        $combined: $i + $y;
        @if ($combined <= 12) {
          .offset-#{$y}-#{$breakpoint}.col-#{$i}-#{$breakpoint} {
            grid-column: #{$y + 1} / span #{$i};
          }
        }
      }
      .col-#{$i}-#{$breakpoint} {
        grid-column-start: span $i;
        max-width: 100%;
      }
    }
  }
}
